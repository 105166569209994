@import '@spartacus/styles/scss/core';
@import '@spartacus/styles/scss/cxbase/blocks/modal';
@import '@spartacus/styles/scss/misc/table';

@mixin visible-focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

@import './base/index';
@import './saved-cart/index';
@import './quick-order/index';
@import './wish-list/index';
@import './import-export/index';
