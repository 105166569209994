@import '../../../theme';

%cx-anonymous-consent-dialog {
  color: var(--cx-color-text);
  background-color: rgba(0, 0, 0, 0.5);

  .cx-anonymous-consent-dialog {
    .cx-dialog-content {
      .cx-dialog-header {
        padding-top: 2rem;
        padding-inline-end: 1.75rem;
        padding-bottom: 0.85rem;
        padding-inline-start: 1.75rem;
        @include cx-highContrastTheme {
          background-color: var(--cx-color-background);
        }
      }
      .cx-dialog-body {
        padding-top: 0;
        background-color: var(--cx-color-inverse);

        @include media-breakpoint-only(xs) {
          padding: 1.5rem;
          border-top: 1px solid var(--cx-color-light);
        }
      }
      .cx-dialog-buttons {
        text-align: end;
        padding: 1rem 4rem;
        @include media-breakpoint-only(xs) {
          padding: 1rem;
        }
      }

      @include forFeature('a11yAnonymousConsentMessageInDialog') {
        .cx-dialog-message {
          padding: 1.5rem 1.75rem 0;

          .cx-message {
            margin: 0;
          }
        }
      }

      .cx-action-link {
        margin: 0 0.35rem;

        @include forFeature('a11yUseButtonsForBtnLinks') {
          padding: 0;
          min-height: unset;
        }
      }

      .cx-dialog-row {
        max-width: 100%;
        @include media-breakpoint-down(xs) {
          padding: 0;
        }
      }

      h3 {
        @include type('3');
      }

      .cx-dialog-description {
        @include type();
        padding: 1.5rem 1.75rem 0;
      }

      a:not([href]):not([tabindex]) {
        text-decoration: underline;
      }
    }
  }

  @include cx-highContrastTheme {
    .cx-dialog-content {
      color: var(--cx-color-text);
      background-color: var(--cx-color-background);
      border: 1px solid var(--cx-color-text);
      .cx-dialog-body {
        background-color: var(--cx-color-background);
      }
      .close {
        color: var(--cx-color-text);
      }
    }
  }
}
