%cx-anonymous-consent-management-banner {
  .anonymous-consent-banner {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: var(--cx-color-inverse);
    z-index: 1000;
    border-top: 5px solid var(--cx-color-primary);
    color: var(--cx-color-text);
    padding: 2rem 0;
  }

  .cx-banner-title {
    @include type('3');
    margin-bottom: 0.5rem;
  }

  .cx-banner-description {
    @include type();
  }

  .cx-banner-buttons {
    text-align: end;

    button:last-child {
      margin-inline-start: 1.5rem;
    }

    button {
      @include media-breakpoint-down(xs) {
        margin-bottom: 10px;
      }
    }

    @include media-breakpoint-down(xs) {
      text-align: center;
      margin-top: 1rem;
    }
    @include media-breakpoint-up(md) {
      margin-top: 1rem;
    }
  }

  .anonymous-consent-banner-hidden {
    display: none;
  }

  @include cx-highContrastTheme {
    .anonymous-consent-banner {
      background-color: var(--cx-color-background);
    }
  }

  @include forFeature('a11yScrollToTopPositioning') {
    &:has(.anonymous-consent-banner) ~ cx-scroll-to-top {
      bottom: 180px;
    }
  }
}
