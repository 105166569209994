%cx-store-finder-list-item {
  display: block;
  text-align: start;
  padding: 1rem 0.75rem;
  text-decoration: none;
  position: relative;

  @include media-breakpoint-down(md) {
    padding: 0.5rem;
  }

  &:hover {
    background-color: var(--cx-color-background);
  }

  .cx-store-list-order {
    position: absolute;
    left: -2rem;
  }

  .cx-store-name {
    font-size: var(--cx-font-size, 1rem);
    font-weight: var(--cx-font-weight-semi);
    min-height: 40px;
    cursor: pointer;
  }
  .cx-store-address {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-normal);
  }
  .cx-store-address-street {
    white-space: nowrap;
    @include forFeature('a11yTruncatedTextStoreFinder') {
      white-space: unset;
    }
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-breakpoint-down(md) {
      white-space: normal;
    }
  }
  .cx-store-open {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    color: var(--cx-color-success);
  }
  .cx-store-closed {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    color: var(--cx-color-danger);
  }
  .cx-store-distance {
    font-size: var(--cx-font-size, 0.875rem);
    font-weight: var(--cx-font-weight-bold);
    margin: 1rem 0;
  }
  .cx-button {
    line-height: 2;
    margin: 1rem 0 0;
  }
}
