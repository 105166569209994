%cx-my-interests {
  .cx-product-interests-title {
    margin: 0 auto;
  }
  .cx-product-interests {
    @include media-breakpoint-down(sm) {
      max-width: 1%;
      padding: 0;
    }
  }
  .cx-product-interests-table {
    padding: 1.5rem 0 1.125rem 0;
    margin-bottom: 0;
    border-width: 0 0 1px 0;
    border-color: var(--cx-color-light);
    border-style: solid;

    tr {
      width: 100%;

      @include media-breakpoint-down(sm) {
        border-width: 1px 0 0 0;
        border-color: var(--cx-color-light);
        border-style: solid;

        &:first-child {
          border-width: 1px 0 0 0;
          padding: 1.25rem 0 0 0;
        }
      }
    }

    th {
      padding: 1.5rem 0 1.125rem 0;
      text-align: start;

      &:first-child {
        text-align: center;
      }
    }

    td {
      width: 25%;
      padding: 1.625rem 0;

      &:first-child {
        width: 10%;
        padding-inline-end: 1.25rem;
        padding-inline-start: 1.25rem;
      }
      &:last-child {
        justify-content: flex-end;
      }

      @include media-breakpoint-up(md) {
        &:last-child {
          text-align: center;
        }
      }

      .cx-name {
        a {
          @include type('5');
          color: var(--cx-color-dark);
        }
      }

      .cx-code {
        @include type('7');
        color: var(--cx-color-secondary);
      }

      .cx-property {
        @include type('7');
      }

      .cx-property {
        .cx-label,
        .cx-value {
          display: inline;
        }
      }

      // TODO: (CXSPA-6903) - Remove feature flag next major release
      @include forFeature('a11yCartItemsLinksStyles') {
        .cx-link {
          text-decoration: underline;
          color: var(--cx-color-primary);
        }
      }

      .cx-product-interests-product-stock {
        color: var(--cx-color-primary);
        @include type('8');
        font-size: var(--cx-font-size, 1.188rem);
        font-weight: var(--cx-font-weight-bold);

        @include forFeature('a11yImproveContrast') {
          @include type('6');
        }
      }

      .cx-product-interests-notification {
        .cx-product-interests-type {
          @include type('5');
          color: var(--cx-color-dark);
        }
        @include media-breakpoint-down(sm) {
          padding: 5px 0 0 0;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        display: flex;
        border-width: 0;
        padding: 0 0 0.5rem 0;

        &:first-child {
          padding-inline-start: 0.5rem;
        }

        &:last-child {
          padding-bottom: 1.25rem;
        }
      }
    }
  }

  .cx-product-interests-table-list {
    th {
      &:first-child {
        text-align: start;
      }
      &:last-child {
        text-align: end;
      }
    }

    td {
      &:first-child {
        padding-inline-end: 0;
        padding-inline-start: 0;
      }
      &:last-child {
        text-align: end;
      }
      .cx-product-interests-cell-wrapper {
        display: inline-grid;
        grid-template-columns: 5rem 14rem;
        gap: 1rem;
        text-indent: 0;
      }
      .cx-product-interests-remove-button {
        display: inline-block;
      }
    }
  }

  .cx-product-interests-product-price {
    @include media-breakpoint-down(sm) {
      .cx-product-interests-label,
      span {
        display: inline;
      }
      span {
        padding-inline-start: 2rem;
      }
    }
  }
  .cx-product-interests-subscriptions {
    @include media-breakpoint-down(sm) {
      padding: 5px 0 0 0;
    }
  }

  .cx-product-interests-thead-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .cx-product-interests-placed {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-product-interests-status {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-product-interests-total {
    text-align: end;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-product-interests-label {
    text-transform: uppercase;
    color: var(--cx-color-secondary);

    @include media-breakpoint-down(sm) {
      @include type('8');
      min-width: 110px;
    }
  }

  .cx-product-interests-form-group {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    span {
      margin-inline-end: 0.5rem;
      flex: none;
    }
    cx-sorting {
      width: 100%;
    }
    @include media-breakpoint-down(sm) {
      padding: 1.25rem 0 1.25rem 0;
    }
  }

  .cx-product-interests-sort {
    &.top {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding-top: 0;
      }
    }

    &.bottom {
      display: flex;
      justify-content: space-between;
      padding: 2rem 0 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
  }

  .cx-product-interests-pagination {
    @include media-breakpoint-down(sm) {
      margin: var(--cx-margin, 0 auto);
    }
  }
}
