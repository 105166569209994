%cx-configurator-overview-filter {
  &:not(:empty) {
    padding-inline-end: 30px;
  }
  .cx-overview-filter-header,
  .cx-overview-filter-option {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    margin-block-start: 15px;
    margin-block-end: 15px;
  }

  .cx-overview-filter-header {
    font-weight: bold;
    background-color: var(--cx-color-background);
    border: 1px solid var(--cx-color-light);
    border-radius: 2px;

    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-block-start: 15px;
    padding-block-end: 15px;

    margin-block-start: 25px;
  }

  .cx-no-overview-filters-available {
    padding-inline-start: 6px;
    padding-inline-end: 10px;
    padding-block-start: 15px;

    @include media-breakpoint-only(md) {
      padding-inline-start: 14px;
    }

    @include media-breakpoint-down(sm) {
      padding-inline-start: 10px;
    }
  }

  @include cx-configurator-attribute-visible-focus();
}
