%cx-media {
  position: relative;
  display: block;

  img {
    max-width: 100%;
    max-height: 100%;
    transform: scale(1);
    opacity: 1;
    transition: all 0.6s;
  }

  &.is-missing {
    @extend %cx-media-missing !optional;
    height: 100%;
  }
}

%cx-media-missing {
  background: no-repeat top center
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 515 515" xml:space="preserve"%3E%3Cstyle type="text/css"%3E.st0%7Bfill:%23FFFFFF;%7D.st1%7Bfill:%23D3D6DB;%7D%3C/style%3E%3Crect y="0" class="st0" width="515" height="515"/%3E%3Cpath class="st1" d="M352.6,198.7h-31.9v15.6h31.9V198.7z M279.3,293.6c-5.9,5.2-14.8,8.1-23,8.1c-22.2,0-39.3-17.8-39.3-40 c0-1.5,0-3.7,0-5.2L279.3,293.6z M296.3,261.7c0,2.2,0,5.2,0,5.9l-64.4-37.8c7.4-5.2,15.6-8.9,24.4-8.9 C278.6,221.7,296.3,240.2,296.3,261.7z M129.7,204.7v120c0,8.9,5.9,15.6,15.6,15.6h214.1l-65.9-37.8c-9.6,8.9-23,14.8-37.8,14.8 c-30.4,0-54.8-25.9-54.8-55.6c0-5.2,0-9.6,1.5-13.3L129.7,204.7z M115.6,170.6L406,341.7l5.2,2.2l-2.2,5.9l-2.2,2.2l-2.2,5.9 l-5.9-3.7L108.9,183.2l-5.9-3.7l3.7-5.2l1.5-2.2l3.7-5.9L115.6,170.6z M206.7,181.7l-1.5-9.6c-1.5-2.2-2.2-3.7-5.2-3.7h-15.6h-14.8 c-2.2,0-3.7,1.5-5.2,3.7l-2.2,9.6h-1.5h-11.9l68.1,40c9.6-9.6,24.4-15.6,39.3-15.6c31.9,0,55.6,25.2,55.6,55.6 c0,5.2-1.5,9.6-2.2,14.8l74.1,43.7V198.7c0-9.6-7.4-17-15.6-17h-15.6c-5.2-1.5-8.9-5.2-11.1-8.9l-2.2-3.7 c-3.7-5.9-11.9-11.1-18.5-11.1h-45.2c-7.4,0-15.6,5.2-19.3,11.1l-3.7,5.2c-2.2,2.2-5.9,5.9-9.6,7.4h-23h-11.1H206.7L206.7,181.7z"/%3E%3C/svg%3E');
  background-size: contain;

  @include forFeature('a11yImproveContrast') {
    background: no-repeat top center
      url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" viewBox="0 0 515 515" xml:space="preserve"%3E%3Cstyle type="text/css"%3E.st0%7Bfill:%23FFFFFF;%7D.st1%7Bfill:%2377818B;%7D%3C/style%3E%3Crect y="0" class="st0" width="515" height="515"/%3E%3Cpath class="st1" d="M352.6,198.7h-31.9v15.6h31.9V198.7z M279.3,293.6c-5.9,5.2-14.8,8.1-23,8.1c-22.2,0-39.3-17.8-39.3-40 c0-1.5,0-3.7,0-5.2L279.3,293.6z M296.3,261.7c0,2.2,0,5.2,0,5.9l-64.4-37.8c7.4-5.2,15.6-8.9,24.4-8.9 C278.6,221.7,296.3,240.2,296.3,261.7z M129.7,204.7v120c0,8.9,5.9,15.6,15.6,15.6h214.1l-65.9-37.8c-9.6,8.9-23,14.8-37.8,14.8 c-30.4,0-54.8-25.9-54.8-55.6c0-5.2,0-9.6,1.5-13.3L129.7,204.7z M115.6,170.6L406,341.7l5.2,2.2l-2.2,5.9l-2.2,2.2l-2.2,5.9 l-5.9-3.7L108.9,183.2l-5.9-3.7l3.7-5.2l1.5-2.2l3.7-5.9L115.6,170.6z M206.7,181.7l-1.5-9.6c-1.5-2.2-2.2-3.7-5.2-3.7h-15.6h-14.8 c-2.2,0-3.7,1.5-5.2,3.7l-2.2,9.6h-1.5h-11.9l68.1,40c9.6-9.6,24.4-15.6,39.3-15.6c31.9,0,55.6,25.2,55.6,55.6 c0,5.2-1.5,9.6-2.2,14.8l74.1,43.7V198.7c0-9.6-7.4-17-15.6-17h-15.6c-5.2-1.5-8.9-5.2-11.1-8.9l-2.2-3.7 c-3.7-5.9-11.9-11.1-18.5-11.1h-45.2c-7.4,0-15.6,5.2-19.3,11.1l-3.7,5.2c-2.2,2.2-5.9,5.9-9.6,7.4h-23h-11.1H206.7L206.7,181.7z"/%3E%3C/svg%3E');
  }
}
