%cx-added-to-cart-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .cx-modal-content {
    @include media-breakpoint-down(md) {
      height: 100%;
    }
  }

  .cx-dialog-header {
    padding-top: 2rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.85rem;
    padding-inline-start: 1.75rem;
    border-width: 0;
    display: flex;
    @include cx-highContrastTheme {
      background-color: var(--cx-color-background);
    }
  }

  .cx-dialog-title {
    @include type('3');
    align-self: flex-start;
  }

  .cx-dialog-body {
    padding: 1rem;
    @include media-breakpoint-down(sm) {
      padding: 0;
      background-color: var(--cx-color-inverse);
    }
    .cx-remove-btn {
      max-width: 100%;
      padding: 0;
      flex: 0 0 100%;
    }
    @include cx-highContrastTheme {
      background-color: var(--cx-color-background);
    }
  }

  .cx-dialog-row {
    margin: 0;
    display: flex;
    padding-top: 0;
    padding-inline-end: 0.875rem;
    padding-bottom: 0.85rem;
    padding-inline-start: 2.875rem;

    max-width: 100%;
    flex-wrap: wrap;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: 0;
    }
  }

  .cx-dialog-item {
    @include media-breakpoint-down(sm) {
      padding: 2rem;
    }

    .cx-image-container {
      a {
        padding: 0;
        max-width: 65px;
      }
    }

    .cx-compact {
      .cx-info {
        @include media-breakpoint-up(md) {
          padding: 0 25px;
        }
      }

      .cx-price,
      .cx-quantity,
      .cx-total {
        .cx-label {
          font-weight: var(--cx-font-weight-normal);
        }
      }

      @include forFeature('a11yPreventHorizontalScroll') {
        .cx-quantity {
          @include media-breakpoint-down(xs) {
            flex-direction: column;
            align-items: start;
            gap: 5px;
          }
        }
      }

      .cx-actions {
        justify-content: flex-start;
      }
    }
  }

  .cx-dialog-separator {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: var(--cx-color-light);
  }

  .cx-dialog-actions {
    display: flex;
    flex-direction: column;
    padding-inline-start: 2.5rem;
    padding-inline-end: 1rem;
    border-width: 0 0 0 0;
    border-inline-start-width: 1px;
    border-style: solid;
    border-color: var(--cx-color-light);

    @include media-breakpoint-down(sm) {
      border-width: 0;
      padding: 1.875rem;
    }
  }

  .cx-dialog-promotions {
    padding: 0 0 1.25rem 0;
  }

  .cx-dialog-total {
    @include type('4');
    display: flex;
    justify-content: space-between;
    padding: 0 0 1.25rem 0;
  }

  .cx-dialog-buttons {
    display: flex;
    flex-direction: column;

    .btn-primary {
      margin: 0 0 0.625rem 0;
    }
  }

  .cx-dialog-pickup-store {
    padding-inline-start: 4.063rem;
    .cx-dialog-pickup-store-name {
      font-weight: var(--cx-font-weight-semi);
    }
  }
}
