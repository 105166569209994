@mixin cx-configurator-attribute-visible-focus {
  .form-check {
    input {
      &:focus {
        outline: var(--cx-visual-focus-width, 2px) auto
          var(--cx-color-visual-focus);
        outline-style: solid;
        outline-color: var(--cx-color-visual-focus);
        outline-offset: 4px;
        transition: none;
      }
    }
  }
}
