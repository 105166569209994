@import '../../theme';
@import '../../mixins';

//alert - https://github.com/twbs/bootstrap/blob/v4-dev/scss/alert.scss
.alert {
  text-align: var(--cx-text-align, center);
  color: var(--cx-color, var(--cx-color-text));
  @include type('6', 'semi');
  padding-top: 0;
  padding-inline-end: 4rem;
  padding-bottom: 0;
  padding-inline-start: 20px;
  line-height: var(--cx-line-height, 1.5);
  min-height: 70px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    @include type('6');
  }

  &-icon {
    height: var(--cx-height, 25px);
    margin-inline-end: var(--cx-margin-right, spacer(2));
    position: var(--cx-position, relative);
    display: var(--cx-display, inline-block);
    vertical-align: var(--cx-vertical-align, middle);
    &::before {
      position: var(--cx-position, relative);
      font-size: 18px;
      font-weight: $font-weight-bold;
      text-align: var(--cx-text-align, center);
      display: var(--cx-display, inline-block);
      width: var(--cx-width, 26px);
      height: var(--cx-height, 26px);
    }
  }

  &-success {
    background-color: mix(#ffffff, theme-color('success'), 80%);
    border: var(--cx-border, none);
    .alert-icon {
      cx-icon {
        color: var(--cx-color, var(--cx-color-success));
      }
    }
  }

  &-danger {
    background-color: mix(#ffffff, theme-color('danger'), 85%);
    border: var(--cx-border, none);
    .alert-icon {
      cx-icon {
        color: var(--cx-color, var(--cx-color-danger));
      }
    }
  }

  &-info {
    background-color: mix(#ffffff, theme-color('info'), 80%);
    border: var(--cx-border, none);
    .alert-icon {
      cx-icon {
        color: var(--cx-color, var(--cx-color-info));
      }
    }
  }

  &-warning {
    background-color: mix(#ffffff, theme-color('warning'), 78%);
    border: var(--cx-border, none);
    .alert-icon {
      cx-icon {
        color: var(--cx-color, var(--cx-color-warning));
      }
    }
  }

  .close {
    font-weight: var(--cx-font-weight, var(--cx-font-weight-normal));
    position: var(--cx-position, absolute);
    top: var(--cx-top, 32%);
    right: auto;
    &::before {
      content: '';
      margin: 0 45vw;
    }
  }
}
