%cx-registration-verification-token-form {
  --cx-max-width: 50%;
  .resend-link-text {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto;

    .left-text {
      padding: 0;
      width: 50%;
      text-align: start;
    }

    .right-text {
      padding: 0;
      width: 50%;
      text-align: end;
    }

    a.disabled-link {
      pointer-events: none;
      color: var(--cx-color-dark);
    }

    a {
      color: var(--cx-color-primary);
      @include type(7);
    }
  }

  .verify-container {
    width: 100%;
    margin-top: 2.5rem;
  }

  .input-hint {
    font-size: 0.875rem;
    color: var(--cx-color-secondary);
    margin-bottom: 0;
  }

  cx-spinner {
    display: none;
  }

  button {
    flex: 100%;
  }

  .label-content {
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.361875rem;
    margin-top: 1.25rem;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .rate-limit-error-display {
    font-size: 0.875rem;
    margin: 0.375rem 0;
    padding-inline-start: 1.5625rem;
    position: relative;
    word-break: break-word;

    @include forFeature('a11yImproveContrast') {
      @include type('7');
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 1.25rem;
      height: 1.25rem;
    }

    &::before {
      content: '';
      background-color: var(--cx-color-danger);
      border-radius: 50%;
    }

    &::after {
      content: '!';
      // TODO: (CXSPA-7588) - Remove feature flag next major release
      @include forFeature('a11yFormErrorMuteIcon') {
        content: '!' / '';
      }
      color: var(--cx-color-inverse);
      font-weight: var(--cx-font-weight-bold);
      text-align: center;
      line-height: 1.25rem;
    }

    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
}
