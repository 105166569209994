%cx-wish-list {
  @extend %cx-cart-item-list;

  td.cx-actions {
    cx-add-to-cart {
      display: flex;
      justify-content: flex-end;

      @include media-breakpoint-down(md) {
        width: 100%;

        form {
          width: inherit;
        }

        button {
          width: 100%;
          text-align: center;
        }
      }
    }

    @include forFeature('a11yVisibleFocusOverflows') {
      .cx-remove-btn {
        margin-top: 0.5rem;
      }
    }
  }

  .cx-empty-wish-list {
    text-align: center;
  }

  @include media-breakpoint-down(md) {
    --cx-max-width: 75%;

    .cx-item-list-row:last-of-type {
      border: none;
    }
  }

  @include media-breakpoint-down(sm) {
    --cx-max-width: 100%;
  }

  @include cx-highContrastTheme {
    tr {
      border-color: var(--cx-color-dark);
    }
    .cx-item-list-items tr:last-child {
      border-color: var(--cx-color-dark);
    }
  }
}
