/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

// stylelint-disable declaration-no-important

// Typography

@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
  #{$parent} {
    color: $color !important;
  }
  @if $emphasized-link-hover-darken-percentage != 0 {
    a#{$parent} {
      @include hover-focus() {
        color: darken(
          $color,
          $emphasized-link-hover-darken-percentage
        ) !important;
      }
    }
  }
  @include deprecate(
    '`text-emphasis-variant()`',
    'v4.4.0',
    'v5',
    $ignore-warning
  );
}
