/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

// stylelint-disable declaration-no-important

@each $value in $user-selects {
  .user-select-#{$value} {
    user-select: $value !important;
  }
}
