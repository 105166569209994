%cx-configurator-issues-notification {
  display: none;
  width: 100%;

  &:not(:empty) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(245, 206, 206, 1);
    font-weight: var(--cx-font-weight-semi);

    padding-inline-start: 5px;
    padding-inline-end: 5px;
    padding-block-start: 5px;
    padding-block-end: 5px;

    margin-block-end: 1.25rem;

    cx-icon {
      align-self: flex-start;
      color: var(--cx-color-danger);
      font-size: 30px;
      padding-inline-start: 15px;
      padding-inline-end: 15px;
      padding-block-start: 5px;
      padding-block-end: 5px;
    }

    #cx-error-msg {
      word-break: break-word;
      padding-inline-end: 15px;

      @include media-breakpoint-down(sm) {
        padding-inline-end: 85px;
      }

      .cx-error-msg-action {
        button.link {
          color: var(--cx-color-text);
          text-decoration: underline;

          &:hover {
            color: var(--cx-color-primary);
            text-decoration: none;
          }
        }
      }
    }

    @include cx-highContrastTheme {
      background-color: var(--cx-color-background);
    }
  }
}
