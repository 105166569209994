/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

// Horizontal dividers
//
// Dividers (basically an hr) within dropdowns and nav lists

@mixin nav-divider(
  $color: $nav-divider-color,
  $margin-y: $nav-divider-margin-y,
  $ignore-warning: false
) {
  height: 0;
  margin: $margin-y 0;
  overflow: hidden;
  border-top: 1px solid $color;
  @include deprecate(
    'The `nav-divider()` mixin',
    'v4.4.0',
    'v5',
    $ignore-warning
  );
}
