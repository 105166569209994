%cx-product-list {
  cx-star-rating {
    padding-top: 0;
  }

  .cx-product-search-list {
    display: block;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0 0 25px 0;

    @include forFeature('a11yImproveContrast') {
      border-color: var(--cx-color-medium);
    }

    &:last-of-type {
      border: none;
    }
  }

  .cx-product-container {
    margin: 40px 0;
  }

  .cx-sorting {
    border-style: solid;
    border-color: var(--cx-color-light);

    @include forFeature('a11yImproveContrast') {
      border-color: var(--cx-color-medium);
    }

    @include media-breakpoint-down(md) {
      border: none;
    }

    &.top {
      border-width: 0 0 1px 0;
      padding: 0 0 8px 0;
    }

    &.bottom {
      border-width: 1px 0 0 0;
      padding: 25px 0 0px 0;
    }

    // TODO: (CXSPA-5926) Remove feaure flag next major release
    @include forFeature('a11ySortingOptionsTruncation') {
      & ng-select {
        @include media-breakpoint-up(lg) {
          width: 15rem;
        }
      }
    }

    // TODO: (CXSPA-6003) Remove feature flags next major
    @include forFeature('a11yTruncatedTextForResponsiveView') {
      @include media-breakpoint-only(xs) {
        .row {
          .col-auto:first-of-type {
            padding-inline-start: 0;
            padding-inline-end: 10px;
          }
          .col-auto:last-of-type {
            padding-inline-end: 10px;
            padding-inline-start: 0;
          }
        }
      }
    }
  }

  .cx-pagination {
    display: inline-block;
    vertical-align: top;

    .page-link:hover {
      color: var(--cx-color-inverse);
    }
  }

  .cx-sort-dropdown {
    height: 48px;
    align-items: center;
    display: flex;

    // TODO: (CXSPA-6003) Remove feature flags next major
    @include forFeature('a11yTruncatedTextForResponsiveView') {
      @include media-breakpoint-only(xs) {
        display: unset;
        height: 80px;
      }
    }

    span {
      margin-inline-end: 0.5rem;
      flex: none;
    }
    cx-sorting {
      width: 100%;
    }
    .ng-arrow-wrapper {
      padding-inline-end: 35px;
    }
  }

  .cx-double-btn-container {
    display: grid;
    grid-gap: 5%;
    grid-template-columns: 1fr 1fr;
  }

  .cx-single-btn-container {
    display: grid;
    width: 50%;
    margin: auto;
  }

  .cx-single-btn-container,
  .cx-double-btn-container {
    .btn-block {
      cursor: pointer;
    }
  }

  .grid-btn-padding {
    padding-top: 25px;
  }

  .align-btn {
    margin-top: 0px !important;
  }

  @include cx-highContrastTheme {
    .cx-sort-dropdown {
      color: var(--cx-color-text);
    }
  }
}
