%cx-configurator-attribute-single-selection-bundle-dropdown {
  @include cx-configurator-attribute-type();
  @include cx-configurator-form-group();

  @include forFeature('productConfiguratorAttributeTypesV2') {
    .form-group {
      @include media-breakpoint-up(md) {
        select {
          width: 80%;
        }
      }
      @include media-breakpoint-down(sm) {
        flex-direction: column;

        select {
          width: 100%;
        }
      }
    }
  }

  flex-direction: column;

  cx-configurator-attribute-product-card {
    @include forFeature('productConfiguratorAttributeTypesV2') {
      width: 100%;
    }

    .cx-product-card {
      @include media-breakpoint-down(sm) {
        padding-inline-start: 0px;
        padding-inline-end: 0px;
      }
    }

    &:first-of-type {
      .cx-product-card-rows {
        padding-inline-start: 0px;
      }
    }

    .cx-product-card-rows {
      &.column {
        padding-inline-end: 0px;
      }
    }

    &:first-of-type {
      .cx-product-card {
        border-top: none;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-inline-start: -17px;
      margin-inline-end: -17px;
    }
  }

  .cx-attribute-level-quantity-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block-start: 32px;
    margin-block-end: 32px;

    @include media-breakpoint-down(sm) {
      margin-inline-end: 0px;
    }

    cx-configurator-attribute-quantity {
      padding-inline-end: 10px;
    }
  }
}
