%cx-order-history {
  .cx-order-history-container {
    @include forFeature('a11yTabComponent') {
      padding: 0 20px;
    }
  }

  .cx-order-history {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding: 0;
    }
  }

  .cx-order-history-table {
    padding: 1.5rem 0 1.125rem 0;
    margin-bottom: 0;
    border-top: 1px solid var(--cx-color-light);
    border-bottom: 1px solid var(--cx-color-light);

    th {
      padding: 1.5rem 0 1.125rem 0;
      text-align: start;

      &:last-child {
        text-align: end;
      }
    }

    td {
      width: 25%;
      padding: 1.625rem 0;

      @include media-breakpoint-up(md) {
        text-align: start;

        &:last-child {
          text-align: end;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        display: flex;
        border-width: 0;
        padding: 0 1.25rem;

        &:first-child {
          padding-top: 1.25rem;
        }

        &:last-child {
          padding-bottom: 1.25rem;
        }
      }
    }
  }

  .cx-order-history-table.cx-order-history-table-po {
    td {
      width: 20%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .cx-order-history-header {
    padding: 40px 0 0 0;
    color: var(--cx-color-text);

    @include media-breakpoint-down(sm) {
      padding-top: 40px;
      padding-inline-end: 20px;
      padding-bottom: 0;
      padding-inline-start: 20px;
    }

    @include forFeature('a11yTabComponent') {
      padding: 0;
    }
  }

  .cx-order-history-thead-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .cx-order-history-code {
    .cx-order-history-value {
      color: var(--cx-color-primary);
      text-decoration: underline;
    }
  }

  .cx-order-history-po {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }
  .cx-order-history-cost-center {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-order-history-placed {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-order-history-status {
    text-align: center;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-order-history-total {
    text-align: end;

    @include media-breakpoint-down(sm) {
      text-align: start;
    }
  }

  .cx-order-history-label {
    text-transform: uppercase;
    color: var(--cx-color-secondary);

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      @include type('8');
      min-width: 110px;
    }
  }

  .cx-order-history-value {
    color: var(--cx-color-text);

    @include media-breakpoint-down(sm) {
      @include type('5');
      font-weight: $font-weight-normal;
    }
  }

  .cx-order-history-form-group {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    width: 40%;

    span {
      margin-inline-end: 0.5rem;
      flex: none;
    }

    cx-sorting {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 1.25rem;
      width: 100%;
    }
  }

  .cx-order-history-sort {
    &.top {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding-top: 0;
      }
    }

    &.bottom {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
  }

  .cx-order-history-pagination {
    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }
  }

  .cx-order-history-no-order {
    display: flex;
    flex-wrap: wrap;
    @include type('5');
    font-weight: $font-weight-normal;
    min-height: 415px;

    @include media-breakpoint-down(sm) {
      min-height: 474px;
      padding-inline-start: 1.25rem;
      padding-inline-end: 1.25rem;
    }

    .btn {
      margin: 1.25rem 0;
    }
  }

  .cx-replenishment-details-order-history-header {
    margin-top: 30px;
    padding: 20px;

    background-color: var(--cx-color-background);

    h4 {
      margin-bottom: 0;
      font-weight: bold;
    }
  }

  .cx-replenishment-details-order-history-no-order {
    display: flex;
    justify-content: center;

    padding: 40px 0 0 0;
    min-height: 0;
  }
}
