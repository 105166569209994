%cx-item-counter {
  width: 146px;

  input {
    text-align: center;

    // avpid native increase/decrease buttons on the numeric field
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      appearance: none;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  &.readonly {
    button {
      display: none;
    }

    input {
      border: none;
      cursor: text;
      width: 65px;
    }
  }

  &:not(.readonly) {
    border-radius: 4px;
    border: solid 1px var(--cx-color-light);
    display: inline-flex;

    @include forFeature('a11yImproveContrast') {
      border: solid 1px var(--cx-color-dark);
    }

    input {
      border: solid 1px var(--cx-color-light);
      border-width: 0 1px;
      padding: 12px 9px;
      max-height: 48px;
      min-width: 46%;
      max-width: 46%;
      &:focus {
        background: var(--cx-color-background);
      }

      @include forFeature('a11yImproveContrast') {
        border-color: var(--cx-color-dark);
        &:focus {
          background-color: var(--cx-color-transparent);
          box-shadow: 0 0 0 0;
        }
      }
    }
  }

  button {
    background-color: var(--cx-color-transparent);
    border: none;
    color: var(--cx-color-text);
    cursor: pointer;
    margin: 0;
    max-height: 48px;
    min-width: 27%;
    max-width: 27%;

    @include type(3);

    &:hover {
      color: var(--cx-color-primary);
    }

    &:focus {
      color: var(--cx-color, var(--cx-color-primary));
      cursor: pointer;
      font-weight: var(--cx-font-weight-bold);
    }

    // TODO: The styles for ":disabled" selector should be deleted when "a11yItemCounterFocus" feature flag has been removed
    &:disabled {
      color: var(--cx-color-light);
      cursor: not-allowed;
    }

    @include forFeature('a11yItemCounterFocus') {
      &[aria-disabled='true'] {
        color: var(--cx-color-light);
        cursor: not-allowed;
      }
    }

    @include forFeature('a11yImproveContrast') {
      font-size: var(--cx-font-size, 1.7rem);
      line-height: 0;
      color: var(--cx-color-text);
      padding-inline-start: 0;
      padding-inline-end: 0;
      &:focus {
        box-shadow: 0 0 0 0;
      }
      // TODO: The styles for ":disabled" selector should be deleted when "a11yItemCounterFocus" feature flag has been removed
      &:disabled {
        color: var(--cx-color-dark);
      }
      @include forFeature('a11yItemCounterFocus') {
        &[aria-disabled='true'] {
          color: var(--cx-color-dark);
        }
      }
    }
  }

  :disabled {
    cursor: not-allowed;
  }

  @include cx-highContrastTheme {
    background-color: var(--cx-color-background);
    input {
      background-color: var(--cx-color-background);
      color: var(--cx-color-text);
    }
    input:disabled {
      color: var(--cx-color-text);
      background-color: var(--cx-color-background);
    }
  }
}
