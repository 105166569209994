%cx-pickup-options {
  .cx-action-link {
    @include type('6');
  }

  @include forFeature('a11yPickupOptionsTabs') {
    .cx-pickup-options {
      margin-bottom: 1.5rem;
    }

    .cx-pickup-options-container {
      background-color: var(--cx-color-background);
      border-radius: 1rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .cx-pickup-options-legend {
      font-weight: bold;
      margin: 0;
    }
  }
  .cx-pickup-store {
    &:after {
      content: '|' / '';
      display: inline-block;
      text-decoration: none;
      margin-inline-start: 0.3rem;
      margin-inline-end: 0.3rem;
    }
  }

  cx-tab {
    --cx-tab-btn-width: 50%;
    --cx-tab-btn-border: 1px var(--cx-color-light) solid;
    --cx-tab-btn-border-radius: 1rem;
    --cx-tab-btn-font-size: 1rem;
    --cx-tab-panel-bg: var(--cx-color-inverse);
    --cx-tab-panel-padding: 0.5rem 1rem;
    --cx-tab-gap: 1rem;
    --cx-tab-btn-bg-color: var(--cx-color-inverse);
    --cx-tab-panel-margin-top: 1rem;
    --cx-tab-panel-border-radius: 1rem;
    --cx-tab-panel-border-top: 1px var(--cx-color-light) solid;
    --cx-tab-panel-border-end: 1px var(--cx-color-light) solid;
    --cx-tab-panel-border-bottom: 1px var(--cx-color-light) solid;
    --cx-tab-panel-border-start: 1px var(--cx-color-light) solid;
  }

  .cx-invalid-message {
    font-size: 14px;
    margin: 10px 0;
    display: block;
    padding-inline-start: 25px;
    position: relative;
    word-break: break-word;

    @include forFeature('a11yImproveContrast') {
      @include type('7');
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
    }

    &::before {
      content: '';
      background-color: var(--cx-color-danger);
      border-radius: 50%;
    }

    &::after {
      content: '!' / '';
      color: var(--cx-color-inverse);
      font-weight: var(--cx-font-weight-bold);
      text-align: center;
      line-height: 20px;
    }

    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  @include cx-highContrastTheme {
    cx-tab {
      button {
        background-color: var(--cx-color-background);
        border: 2px solid var(--cx-color-text);
        color: var(--cx-color-text);
      }

      cx-tab-panel {
        div[role='tabpanel'] {
          background-color: var(--cx-color-background);
          border: 2px solid var(--cx-color-text);
          color: var(--cx-color-text);
        }
      }
    }
  }
}
