.cx-notice {
  padding: 32px 0;
  text-align: center;
  @include type();
  color: var(--cx-color-dark);
  background-color: var(--cx-color-inverse);
  margin-bottom: -1.5rem;

  @include forFeature('a11yImproveContrast') {
    color: var(--cx-color-text);
  }

  @include cx-highContrastTheme {
    background-color: var(--cx-color-background);
  }
}
