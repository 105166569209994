$sapFiori_Button_Standard_TextColor: #1d2d3e;
$sapFiori_Button_Standard_Background: #eaecee;
$sapFiori_Button_Standard_BorderColor: transparent;
$sapFiori_Button_Standard_Hover_TextColor: #1d2d3e;
$sapFiori_Button_Standard_Hover_Background: #ffffff;
$sapFiori_Button_Standard_Hover_BorderColor: transparent;
$sapFiori_Button_Standard_Active_TextColor: #0070f2;
$sapFiori_Button_Standard_Active_Background: #ffffff;
$sapFiori_Button_Standard_Active_BorderColor: #0070f2;

$sapFiori_Button_Lite_TextColor: #0064d9;
$sapFiori_Button_Lite_Hover_Background: #ffffff;
$sapFiori_Button_Lite_Active_Background: #ffffff;
$sapFiori_Button_Lite_Active_TextColor: #0070f2;
$sapFiori_Button_Lite_Active_BorderColor: #0070f2;

$sapFiori_Button_Emphasized_TextColor: #ffffff;
$sapFiori_Button_Emphasized_Background: #0070f2;
$sapFiori_Button_Emphasized_BorderColor: transparent;
$sapFiori_Button_Emphasized_Hover_Background: #0064d9;
$sapFiori_Button_Emphasized_Hover_BorderColor: transparent;
$sapFiori_Button_Emphasized_Active_TextColor: #0070f2;
$sapFiori_Button_Emphasized_Active_Background: #ffffff;
$sapFiori_Button_Emphasized_Active_BorderColor: #0070f2;

%cx-asm-switch-customer-dialog {
  .cx-modal-content {
    max-width: 477px;
    margin-inline-start: auto;
    margin-inline-end: auto;
  }

  .cx-dialog-header {
    padding-top: 0.9rem;
    padding-inline-end: 1rem;
    padding-bottom: 0.9rem;
    padding-inline-start: 1rem;
    border-width: 0;
    display: flex;

    box-shadow:
      0px 0px 4px rgba(85, 107, 130, 0.16),
      inset 0px -1px 0px #d9d9d9;

    .title {
      font-family: '72';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
    }

    .cx-icon {
      color: #e76500;
    }

    .close {
      font-size: 1rem;
      color: $sapFiori_Button_Standard_TextColor;
    }
  }

  .cx-dialog-body::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #dd6100;
  }

  .modal-body {
    padding: 0;
  }

  .cx-dialog-body {
    .switch-customer-content {
      padding: 16px;
      font-family: '72';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }

  .cx-dialog-footer {
    padding-top: 0;
    padding-inline-end: 1rem;
    padding-bottom: 0;
    padding-inline-start: 1rem;
  }

  .btn {
    color: $sapFiori_Button_Standard_TextColor;
    transition: opacity 0.3s;
    font-size: 14px;
    padding-top: 0;
    padding-bottom: 0;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    min-width: 48px;
    min-height: 36px;
    line-height: 1.34;
    border-radius: 8px;
    border: 1px solid transparent;
    font-weight: 600;

    &:hover,
    &:focus {
      box-shadow: 0px 0px 4px rgba(120, 143, 166, 0.52);
      background-color: $sapFiori_Button_Lite_Hover_Background;
      filter: none;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: $sapFiori_Button_Lite_Active_TextColor;
      background-color: $sapFiori_Button_Lite_Active_Background;
      border-color: $sapFiori_Button_Lite_Active_BorderColor;
      filter: none;
    }

    &-primary {
      color: $sapFiori_Button_Emphasized_TextColor;
      border-color: $sapFiori_Button_Emphasized_BorderColor;
      background-color: $sapFiori_Button_Emphasized_Background;
      font-weight: 700;

      &:hover,
      &:focus {
        background-color: $sapFiori_Button_Emphasized_Hover_Background;
        border-color: $sapFiori_Button_Emphasized_Hover_BorderColor;
        box-shadow: none;
        filter: none;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: $sapFiori_Button_Emphasized_Active_TextColor;
        background-color: $sapFiori_Button_Emphasized_Active_Background;
        border-color: $sapFiori_Button_Emphasized_Active_BorderColor;
        filter: none;
      }
    }

    &-secondary {
      color: $sapFiori_Button_Standard_TextColor;
      border-color: $sapFiori_Button_Standard_BorderColor;
      background-color: $sapFiori_Button_Standard_Background;

      &:hover,
      &:focus {
        color: $sapFiori_Button_Standard_Hover_TextColor;
        background-color: $sapFiori_Button_Standard_Hover_Background;
        border-color: $sapFiori_Button_Standard_Hover_BorderColor;
        box-shadow: 0px 0px 4px rgba(85, 107, 130, 0.72);
        filter: none;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: $sapFiori_Button_Standard_Active_TextColor;
        background-color: $sapFiori_Button_Standard_Active_Background;
        border-color: $sapFiori_Button_Standard_Active_BorderColor;
        filter: none;
      }
    }
  }
}
