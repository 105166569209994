%cx-store-search {
  .cx-find-a-store-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;

    .cx-find-a-store-label {
      @include type('6');
      width: 100%;
    }

    .cx-find-a-store-input {
      flex-grow: 1;
      padding-bottom: 1rem;
      flex-basis: 50%;
      input {
        width: 100%;
        height: 48px;
      }
    }
    .cx-find-a-store-button {
      flex-basis: 100%;
      margin-top: 0;
      margin-bottom: 0;

      @media screen and (min-width: 927px) {
        flex-basis: 40%;
      }
    }
    .cx-find-a-store-link-container {
      @include media-breakpoint-down(sm) {
        margin: auto;
      }

      .cx-find-a-store-link {
        @include type('6');
        color: $cx-color-link-primary;
        text-decoration: underline;
        text-transform: uppercase;
        cursor: pointer;

        &:nth-of-type(1) {
          margin-inline-end: var(--cx-margin-right, 0.5rem);
        }
        &:nth-of-type(2) {
          margin-inline-start: var(--cx-margin-right, 0.5rem);
        }

        @include media-breakpoint-down(md) {
          display: inline-block;
          margin: auto;
          padding-top: 1.5rem;
        }
      }
    }

    .cx-find-a-store-hide-out-of-stock {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 2rem;
      margin-bottom: 1rem;

      .cx-find-a-store-checkbox-group {
        display: flex;

        @include media-breakpoint-down(sm) {
          flex-basis: 100%;
        }
        .cx-hide-out-of-stock-label {
          @include type('6');
          user-select: none;
        }
      }
    }
  }
}
