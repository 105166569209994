%cx-configurator-exit-button {
  @include forFeature('a11yImproveContrast') {
    .btn-tertiary {
      &:hover {
        text-decoration: underline;
      }

      @include media-breakpoint-up(lg) {
        color: var(--cx-color-inverse);

        &:hover {
          color: var(--cx-color-inverse);
        }

        &:focus {
          color: var(--cx-color-inverse);
        }
      }
    }
  }

  @include cx-highContrastTheme {
    .btn-tertiary {
      color: var(--cx-color-primary);

      &:hover {
        color: var(--cx-color-primary);
      }

      &:focus {
        color: var(--cx-color-primary);
      }
    }
  }
}
