%cx-configurator-add-to-cart-button {
  position: fixed;
  z-index: calc(var(--cx-popover-z-index) + 10);
  bottom: 0;
  width: 100%;
  background-color: var(--cx-color-background);
  border-top: solid 1px var(--cx-color-light);
  @include cx-highContrastTheme {
    border-top: solid 1px var(--cx-color-dark);
  }
  box-shadow: 0 0 5px var(--cx-color-light);

  .cx-add-to-cart-btn-container,
  .cx-display-only-btn-container {
    max-width: 1140px;
    @include cx-configurator-footer-container();
    margin-top: 0px;

    button.cx-add-to-cart-btn,
    button.cx-display-only-btn {
      @include cx-configurator-footer-container-item();
    }
  }

  .cx-add-to-cart-btn-container {
    .cx-quantity-add-to-cart-container {
      @include media-breakpoint-down(sm) {
        inline-size: 100%;
      }

      inline-size: 40%;
      padding-inline-start: 16px;

      @include media-breakpoint-down(sm) {
        padding-inline-start: 0px;
      }

      .cx-quantity-add-to-cart-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .cx-quantity {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-inline-end: 10px;

          label {
            padding-inline-end: 5px;
            font-weight: var(--cx-font-weight-semi);
            margin-block-end: 0px;
          }

          cx-item-counter {
            background-color: var(--cx-color-inverse);
            @include cx-highContrastTheme {
              background-color: var(--cx-color-background);
            }
          }
        }

        button.cx-add-to-cart-btn {
          inline-size: 45%;
        }
      }
    }
  }
}
