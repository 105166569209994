%cx-opf-google-pay {
  .cx-opf-google-pay-button {
    margin: 10px 0;
    height: 48px;
    @include media-breakpoint-down(md) {
      padding: 0;
    }
    .gpay-button,
    .gpay-card-info-container {
      border-radius: var(--cx-buttons-border-radius);
      min-width: auto;
    }
  }
}
