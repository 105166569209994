%cx-close-account-modal {
  .cx-close-account-modal {
    .cx-close-account-modal-container {
      .cx-close-account-modal-header {
        display: flex;
        justify-content: space-between;
        .cx-close-account-modal-title {
          @include type('3');
        }
      }

      .cx-confirmation {
        margin-bottom: 0px;
      }

      .cx-close-account-modal-footer {
        display: flex;

        button {
          flex: 0 0 calc(50% - 0.5rem);
          text-transform: lowercase;

          &:first-line {
            text-transform: capitalize;
          }

          &:first-child {
            margin-inline-end: 0.5rem;
          }

          &:last-child {
            margin-inline-start: 0.5rem;
          }

          &:focus {
            @include visible-focus();
          }
        }
      }
    }
  }
}
