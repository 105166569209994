//Fonts must be added separate from the theme
@import 'fonts';

@import '@fortawesome/fontawesome-free/css/all.css';

@import '@ng-select/ng-select/themes/default.theme.css';

// new
@import 'cxbase/index';

//bootstrap enhancements (these are things we ARE changing)
@import 'cxbase/blocks/buttons';
@import 'cxbase/blocks/forms';
@import 'cxbase/blocks/modal';
@import 'cxbase/blocks/alert';
@import 'cxbase/blocks/message';
@import 'cxbase/blocks/tooltip';
@import 'cxbase/blocks/tables';
@import 'cxbase/blocks/card';
@import 'cxbase/blocks/accordion';
@import 'cxbase/blocks/tabs';
@import 'cxbase/blocks/carousel';
@import 'cxbase/blocks/link';
@import 'cxbase/blocks/dropdown';
//layout
@import 'cxbase/layout/app';
@import 'cxbase/layout/page';
@import 'cxbase/layout/section';
//non-bootstrap blocks and components here below
@import 'cxbase/blocks/paragraph';

// effects & animations
@import 'cxbase/animations';

@import 'misc/hamburger';
@import 'misc/dialog';
@import 'misc/table';
@import 'site-theme';
