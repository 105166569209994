%cx-opf-checkout-payment-and-review {
  @include checkout-media-style();
  margin-top: 1.5rem;

  @include media-breakpoint-down(md) {
    background-color: var(--cx-color-transparent);
  }

  h3 {
    padding-bottom: 1rem;
    margin-bottom: 0;
    font-size: 1.15rem;
  }

  cx-opf-checkout-billing-address-form,
  cx-opf-checkout-payments,
  .cx-opf-terms-and-conditions {
    display: block;
    margin-bottom: 2rem;
    border: 1px solid #d3d6db;
    padding: 1.75rem 1.75rem;
    border-radius: 10px;
    background-color: #ffffff;
  }

  .cx-opf-terms-and-conditions {
    margin-bottom: 2rem;

    .form-check,
    .form-group {
      margin-bottom: 0;
    }
  }

  .cx-review-cart-item {
    padding: 0;
  }

  .cx-review-cart-total {
    font-size: var(--cx-font-size, 1.125rem);
    font-weight: var(--cx-font-weight-bold);
    line-height: var(--cx-line-height, 1.2222222222);
    padding-top: 1rem;
    padding-bottom: 1.25rem;
  }

  .cx-items-to-ship-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 2rem;
    font-weight: var(--cx-font-weight-semi);
    height: 65px;
    background: #f1f1f1;

    @include media-breakpoint-down(md) {
      background: #d3d6db;
    }
  }

  .cx-shipping-and-delivery-info-cards {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-direction: row;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }

    .card-body {
      padding: 0;
    }

    .cx-review-summary-card {
      display: flex;
      flex: 1;
      border: 1px solid #d3d6db;
      border-radius: 10px;
      justify-content: space-between;
      padding: 1.75rem;
      background-color: #ffffff;
    }

    .cx-card-title {
      font-weight: var(--cx-font-weight-semi);
    }
  }

  > section > cx-opf-checkout-terms-and-conditions-alert {
    display: none;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
}
