%organizationItemForm {
  ng-select {
    font-weight: normal;
    .ng-select-container {
      // align the height of the select with buttons
      max-height: 48px;
    }
  }

  form section {
    padding-bottom: 20px;
  }

  label {
    max-width: 100%;
    @include media-breakpoint-up(md) {
      flex: 50%;
    }
    @include media-breakpoint-down(sm) {
      flex: 100%;
    }
    padding-inline-end: 20px;
    padding-bottom: 10px;
    margin: 0;

    .form-check {
      margin: 0;
    }
  }

  .full-width {
    flex: 100%;
  }

  .form-group {
    @include media-breakpoint-up(md) {
      flex: 50%;
    }
    @include media-breakpoint-down(sm) {
      flex: 100%;
    }
    padding-inline-end: 20px;

    cx-form-errors {
      position: relative;
      p {
        padding-inline-start: 30px;
        font-size: 16px;
        font-weight: normal;

        @include forFeature('a11yImproveContrast') {
          @include type('6');
        }
      }
      @include cx-highContrastTheme-dark {
        p::after {
          color: var(--cx-color-medium);
        }
      }
    }
  }

  ng-select {
    font-weight: normal;
    .ng-select-container {
      // align the height of the select with buttons
      max-height: 48px;
    }
  }

  .ng-dropdown-panel {
    span {
      @include media-breakpoint-down(sm) {
        white-space: normal;
      }
    }
  }
}
