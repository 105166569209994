%ProductListPageTemplate,
%ProductGridPageTemplate {
  max-width: var(--cx-page-width-max);
  margin: auto;
  display: flex;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  .ProductLeftRefinements {
    max-width: 25%;
    padding-top: 60px;
    padding-inline-end: 15px;
    padding-bottom: 15px;
    padding-inline-start: 15px;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: none;
    }

    // TODO: (CXSPA-6003) Remove feature flags next major
    @include forFeature('a11yTruncatedTextForResponsiveView') {
      @include media-breakpoint-only(xs) {
        padding-top: 35px;
      }
    }
  }
  .ProductListSlot,
  .ProductGridSlot {
    max-width: 75%;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: none;
    }

    // TODO: (CXSPA-6003) Remove feature flags next major
    @include forFeature('a11yTruncatedTextForResponsiveView') {
      .cx-page-section {
        @include media-breakpoint-only(xs) {
          padding-top: 0;
        }
      }
    }
  }
}
