%cx-opf-checkout-billing-address-form {
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
  }

  .cx-custom-address-info {
    display: flex;
    align-items: flex-start;

    cx-card {
      flex-grow: 1;
    }

    button {
      outline: none;
      border: none;
      background: none;
      margin-top: 1rem;
    }
  }

  .cx-card-body {
    padding: 0 2.25rem;
  }
}
