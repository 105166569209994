/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

@mixin badge-variant($bg) {
  color: color-yiq($bg);
  background-color: $bg;

  @at-root a#{&} {
    @include hover-focus() {
      color: color-yiq($bg);
      background-color: darken($bg, 10%);
    }

    &:focus,
    &.focus {
      outline: 0;
      box-shadow: 0 0 0 $badge-focus-width rgba($bg, 0.5);
    }
  }
}
