/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

// CSS image replacement
@mixin text-hide($ignore-warning: false) {
  // stylelint-disable-next-line font-family-no-missing-generic-family-keyword
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;

  @include deprecate('`text-hide()`', 'v4.1.0', 'v5', $ignore-warning);
}
