%cx-configurator-group-menu {
  &:not(:empty) {
    .cx-group-menu,
    .cx-ghost-group-menu {
      margin-inline-end: 25px;

      @include media-breakpoint-down(md) {
        background-color: var(--cx-color-background);
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin-block-start: 0px;
        margin-block-end: 0px;
      }
    }

    .cx-group-menu {
      list-style-type: none;
      background-color: #ffffff;
      border-width: 1px;
      border-style: solid;
      border-color: var(--cx-color-light);
      border-radius: 2px;
      padding-inline-start: 3px;
      padding-inline-end: 3px;
      padding-block-start: 3px;
      padding-block-end: 3px;
      margin-inline-start: 0px;
      margin-block-start: 0px;
      margin-block-end: 0px;

      .cx-menu-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        text-decoration: none;
        color: var(--cx-color-text);
        border: none;
        background-color: transparent;
        outline-offset: 0px;
        width: 100%;

        line-height: var(--cx-line-height, 1.6);
        border-width: 1px 1px 0 1px;
        border-style: solid;
        border-color: var(--cx-color-light);

        padding-inline-start: 15px;
        padding-inline-end: 15px;
        padding-block-start: 15px;
        padding-block-end: 15px;

        &:hover {
          cursor: pointer;
          color: var(--cx-color-primary);
        }

        &.active {
          color: var(--cx-color-primary);
          font-weight: bold;
        }

        &.disable {
          cursor: not-allowed;
          pointer-events: none;
        }

        span {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          line-break: anywhere;

          @include cx-configurator-truncate-content();
        }

        .groupIndicators {
          display: flex;
          flex-direction: row;
          align-self: center;
          inline-size: 100px;

          .groupStatusIndicator {
            inline-size: 25px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            cx-icon.ERROR,
            cx-icon.WARNING,
            cx-icon.COMPLETE {
              display: none;
            }

            cx-icon.ERROR {
              color: var(--cx-color-danger);
              padding-inline-end: 5px;
            }

            cx-icon.WARNING {
              color: var(--cx-color-warning);
              padding-inline-end: 5px;
            }

            cx-icon.COMPLETE {
              color: var(--cx-color-text);
              padding-inline-end: 5px;
            }
          }

          .subGroupIndicator {
            inline-size: 25px;

            cx-icon {
              margin-inline-start: 5px;
              margin-inline-end: 5px;
            }
          }

          .conflictNumberIndicator {
            inline-size: 25px;
          }
        }

        &.ERROR cx-icon.ERROR,
        &.WARNING cx-icon.WARNING,
        &.COMPLETE cx-icon.COMPLETE {
          display: inline-block !important;
        }

        &.cx-menu-conflict {
          background-color: mix(#ffffff, theme-color('warning'), 78%);
          @include cx-highContrastTheme-dark {
            color: var(--cx-color-light);
          }
        }
      }

      &:last-child {
        border-width: 0 0 1px 0;
      }

      .cx-menu-back {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        text-decoration: none;
        color: var(--cx-color-text);
        border: none;
        outline-offset: 0px;
        width: 100%;

        line-height: var(--cx-line-height, 1.6);
        border-width: 1px 1px 0 1px;
        border-style: solid;
        border-color: var(--cx-color-light);
        background-color: var(--cx-color-background);

        padding-inline-start: 15px;
        padding-inline-end: 15px;
        padding-block-start: 15px;
        padding-block-end: 15px;

        &:hover {
          cursor: pointer;
          color: var(--cx-color-primary);
        }

        &.active {
          color: var(--cx-color-primary);
        }

        cx-icon {
          margin-inline-start: 5px;
          margin-inline-end: 5px;
          margin-block-start: 5px;
          margin-block-end: 5px;
        }
      }

      @include media-breakpoint-down(md) {
        background-color: var(--cx-color-background);

        .cx-menu-back {
          font-weight: var(--cx-font-weight-semi);
          border-bottom: 1px solid var(--cx-color-light);
          background-color: var(--cx-color-medium);
        }

        .cx-menu-item {
          text-transform: uppercase;
          font-weight: var(--cx-font-weight-semi);
        }

        button:not(.cx-menu-conflict) {
          border-bottom: 1px solid var(--cx-color-medium);
        }
      }
    }

    .cx-ghost-group-menu {
      .cx-ghost-menu-item {
        padding-block-end: 5px;

        .cx-ghost-item-title {
          height: 55px;
          background-color: var(--cx-color-ghost);
        }
      }
    }
  }

  @include cx-highContrastTheme {
    .cx-group-menu {
      background-color: var(--cx-color-background);
    }
  }
}
