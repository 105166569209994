@import '../../theme';
@import '../../functions';
@import '../../mixins';
.accordion {
  .card {
    margin-bottom: 20px;
    border: none;
    &-header {
      background-color: theme-color('background');
      padding: 25px 20px;
      border: none;
      h5 {
        font-size: $font-size-base * 1.375;
        font-weight: 600;
        color: theme-color('text');

        @include forFeature('a11yImproveContrast') {
          @include type('3');
        }

        .btn {
          text-align: start;
          text-decoration: none !important;
          width: 100%;
          font-weight: 600;
          &:hover {
            text-decoration: none;
          }
          &:after {
            float: right;
            font-size: 33px;
            bottom: 12px;
            position: relative;
          }
          &[aria-expanded='false'] {
            &:after {
              content: '+';
            }
          }
          &[aria-expanded='true'] {
            color: theme-color('primary');
            &:after {
              content: '\2013';
            }
          }
        }
      }
    }
    &-body {
      padding: 30px 0 20px 0;
    }
  }
}

//cx-accordion is an alterative version of the accordion
//media queries are to be added in each component
//ex. anonymus consent dialog
.cx-accordion {
  margin: 5px auto;
  line-height: 1.6;
  list-style-type: none;
  padding: 0;

  .cx-accordion-item {
    border: 1px solid var(--cx-color-light);
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;

    .cx-accordion-tab {
      background-color: var(--cx-color-inverse);
      &:hover {
        cursor: pointer;
      }
      .cx-accordion-title {
        padding-top: 15px;
        padding-inline-end: 15px;
        padding-bottom: 15px;
        padding-inline-start: 50px;
        display: block;
        position: relative;
        font-weight: 600;
      }
    }

    .cx-accordion-tabpanel {
      transition: height 0.3s ease-out;
      height: 0;
      overflow: hidden;
      background-color: var(--cx-color-inverse);

      .cx-accordion-content {
        padding-top: 0;
        padding-inline-end: 40px;
        padding-bottom: 10px;
        padding-inline-start: 40px;
      }
    }
  }
}
