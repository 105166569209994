%cx-login {
  color: var(--cx-color-text);

  @include media-breakpoint-down(md) {
    > a {
      padding: 1rem;
      display: block;
    }
  }

  .cx-login-greet {
    color: var(--cx-color-text);
    @include type('7');
    font-weight: var(--cx-font-weight-semi);

    @include media-breakpoint-down(md) {
      color: var(--cx-color-text);
      padding: 0.5rem 1rem 0;
      @include type();
    }
  }

  #account-nav {
    @include media-breakpoint-up(lg) {
      nav > ul > li > button {
        padding-top: 4px;
        padding-bottom: 0;

        // TODO: (CXSPA-7205) - Remove feature flag next major release
        @include forFeature('a11yMyAccountLinkOutline') {
          padding-top: 0;
          margin-top: 4px;
          outline-offset: 1px;
        }
      }
    }
  }

  @include cx-highContrastTheme {
    .cx-login-greet {
      background-color: var(--cx-color-background);
    }
  }
}
