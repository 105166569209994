%cx-configurator-restart-dialog {
  &:not(:empty) {
    z-index: 3000;
    .cx-modal-content {
      margin: auto;
      @include media-breakpoint-down(sm) {
        min-height: 100%;
      }
    }
    .cx-dialog-header {
      padding-top: 2rem;
      padding-inline-end: 1.75rem;
      padding-bottom: 1.25rem;
      padding-inline-start: 1.75rem;
      display: flex;
      position: sticky;
      background-color: var(--cx-color-inverse);

      .cx-dialog-title {
        @include type('3');
        align-self: flex-start;
      }
      &.modal-header .close {
        margin-block-start: 0px;
        margin-block-end: 0px;
        padding-block-start: 0px;
        padding-block-end: 0px;
        align-self: flex-start;
      }
      @include cx-highContrastTheme {
        background-color: var(--cx-color-background);
      }
    }

    .cx-dialog-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      padding-inline-start: 16px;
      padding-inline-end: 16px;
      padding-block-start: 16px;
      padding-block-end: 16px;

      @include media-breakpoint-down(sm) {
        padding-block-start: 20px;
        padding-block-end: 20px;
        padding-inline-start: 16px;
        padding-inline-end: 16px;
      }

      #cx-configurator-dialog-description {
        margin-block-start: 8px;
        margin-block-end: 16px;
      }
      .btn-primary,
      .btn-secondary {
        margin-block-start: 8px;
        margin-block-end: 8px;
      }
      @include cx-highContrastTheme {
        background-color: var(--cx-color-background);
      }
    }
  }
}
