%cx-delivery-mode {
  @include checkout-media-style();

  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  padding: 23px 30px 8px;
  background-color: var(--cx-color-inverse);

  @include media-breakpoint-down(md) {
    background-color: var(--cx-color-transparent);
    margin-top: 32px;
    padding: 23px 30px 8px;
  }

  @include media-breakpoint-up(lg) {
    margin: 2rem 0 2rem;
  }

  .cx-delivery-mode-wrapper {
    background-color: var(--cx-color-inverse);
    border: none;
    border-radius: 0px;
    padding: 0;

    @include media-breakpoint-down(md) {
      margin: 0;
    }
  }

  .form-check {
    display: flex;
    margin-bottom: 0;

    .cx-delivery-label {
      padding: 0;
      margin-inline-start: 0.75rem;
      margin-inline-end: auto;
      margin-bottom: 1.5rem;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;

      .cx-delivery-mode {
        flex: auto;
        text-align: start;
      }

      .cx-delivery-price {
        flex: auto;
        text-align: end;
      }

      .cx-delivery-details {
        flex: 100%;
        flex-basis: 100%;
        color: var(--cx-color-success);
      }

      .cx-delivery-mode-description {
        width: 100%;
        display: block;
        color: var(--cx-color-success);
      }
    }
  }

  @include cx-highContrastTheme {
    border: 1px solid var(--cx-color-text);
    background-color: var(--cx-color-background);
    .cx-delivery-mode-wrapper {
      background-color: var(--cx-color-background);
    }
  }
}
