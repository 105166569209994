/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

@import 'utilities/align';
@import 'utilities/background';
@import 'utilities/borders';
@import 'utilities/clearfix';
@import 'utilities/display';
@import 'utilities/embed';
@import 'utilities/flex';
@import 'utilities/float';
@import 'utilities/interactions';
@import 'utilities/overflow';
@import 'utilities/position';
@import 'utilities/screenreaders';
@import 'utilities/shadows';
@import 'utilities/sizing';
@import 'utilities/spacing';
@import 'utilities/stretched-link';
@import 'utilities/text';
@import 'utilities/visibility';
