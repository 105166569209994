@import '@spartacus/styles/scss/core';

@import '@spartacus/styles/scss/cxbase/blocks/modal';
@import './account/styles/index';
@import './profile/styles/index';

$skipComponentStyles: () !default;
$selectors: cx-address-book, cx-address-form, cx-suggested-addresses-dialog,
  cx-login, cx-login-form, cx-register, cx-otp-register-form,
  cx-registration-verification-token-form, cx-reset-password, cx-close-account,
  cx-close-account-modal, cx-my-account-v2-profile, cx-my-account-v2-email,
  cx-my-account-v2-password, cx-otp-login-form, cx-verification-token-form,
  cx-verification-token-dialog !default;

@each $selector in $selectors {
  #{$selector} {
    // skip selectors if they're added to the $skipComponentStyles list
    @if (index($skipComponentStyles, $selector) == null) {
      @extend %#{$selector} !optional;
    }
  }
}

.user-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    width: 50%;
    --cx-max-width: 50%;
  }

  position: relative;

  form {
    display: contents;

    label {
      all: unset;
      flex: 100%;
      margin-bottom: 0.375rem;
    }

    .input-hint {
      font-size: 0.875rem;
      color: var(--cx-color-secondary);
      margin-bottom: 0;
    }
  }

  button,
  a {
    flex: 0 0 calc(50% - 10px);
    margin-top: 0.5rem;
  }
}
