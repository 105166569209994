$sapFioriTextColor: #1d2d3e;
$sapFioriLinkColor: #0070f2;
$sapFioriContent_LabelColor: #6a6d70;

%cx-customer-list {
  .cx-modal-content {
    min-height: 352px;
  }

  .cx-dialog-header {
    padding-top: 2rem;
    padding-inline-end: 1.75rem;
    padding-bottom: 2rem;
    padding-inline-start: 2.3rem;
    border-width: 0;
    display: flex;
    .title {
      font-size: 1.25rem;
      font-weight: 900;
    }
  }

  .cx-dialog-sub-header {
    padding-top: 0;
    padding-inline-end: 1.75rem;
    padding-bottom: 0.5rem;
    padding-inline-start: 1.75rem;
    border-width: 0;
    display: flex;
    .cx-header-select {
      display: flex;
      .cx-total {
        padding: 0.6875rem 1rem;
      }
    }
    .cx-header-select.mobile {
      flex-direction: column;
    }
    .cx-header-actions {
      display: flex;
      label {
        margin: 0;
      }
      .search-wrapper {
        position: relative;
        margin-inline-start: 1rem;

        .form-control {
          padding-inline-end: 46px;
          &:hover,
          &:focus {
            border: 2px solid #004da5;
            box-shadow: 0px 0px 1px rgba(120, 143, 166, 0.72);
            outline: none;
            background-color: #ffffff;
          }
        }
        .form-control::-webkit-input-placeholder {
          color: #74777a;
          font-style: italic;
        }

        cx-icon {
          position: absolute;
          top: 17px;
          right: 17px;
          cursor: pointer;
        }
      }

      .cx-pagination-buttons {
        display: flex;
        align-items: center;
        cx-icon {
          color: $sapFioriLinkColor;
        }
        cx-icon.previous {
          padding-top: 0;
          padding-inline-end: 10px;
          padding-bottom: 0;
          padding-inline-start: 0;
        }
        cx-icon.next {
          padding-top: 0;
          padding-inline-end: 0;
          padding-bottom: 0;
          padding-inline-start: 10px;
        }
        button {
          text-decoration: none;
          color: $sapFioriLinkColor;
        }
        .cx-btn-previous {
          span {
            padding-top: 0;
            padding-inline-end: 0;
            padding-bottom: 0;
            padding-inline-start: 5px;
          }
        }
        .cx-btn-next {
          span {
            padding-top: 0;
            padding-inline-end: 5px;
            padding-bottom: 0;
            padding-inline-start: 0;
          }
        }
        div:first-child {
          font-size: 0.875rem;
          color: $sapFioriTextColor;
          padding: 0 10px;
        }
      }
      .table-header-cell {
        line-height: 40px;
        margin-top: 0;
        margin-inline-end: 15px;
        margin-bottom: 0;
        margin-inline-start: 0;
      }
      .table-header-cell.sort {
        cursor: pointer;
        cx-icon {
          margin: 0 10px;
        }
      }
    }
    .cx-header-actions.mobile {
      flex-direction: column;
    }
  }

  .cx-dialog-sub-header.tablet-mobile {
    flex-direction: column;
  }

  .asm-mobile-pagination {
    flex-direction: row-reverse;
  }

  .cx-dialog-body {
    padding-top: 0;
    padding-bottom: 0;
    max-height: calc(100vh - 260px);
    @include media-breakpoint-down(xs) {
      max-height: calc(100vh - 470px);
    }
    overflow-y: auto;
  }

  .cx-dialog-foot {
    display: flex;
    flex-direction: row-reverse;
  }

  .table {
    thead {
      tr {
        border-top: 1px solid var(--cx-color-light);
        height: 44px;
        th {
          color: $sapFioriTextColor;
          text-transform: none;
          font-weight: 700;
        }
      }
    }
    tbody {
      td {
        font-size: 0.875rem;
        color: $sapFioriTextColor;
        .cx-header-text {
          color: $sapFioriContent_LabelColor;
          font-weight: 400;
        }
      }
    }
  }

  .cx-btn-cell {
    text-decoration: none;
    color: $sapFioriLinkColor;
    padding: 0;
    border: 0;
    min-height: 1.15rem;
    width: fit-content;
  }

  .cx-avatar-cell {
    width: 65px;
    vertical-align: top;
  }

  .cx-cell-container {
    display: flex;
    align-items: center;
    min-height: 44px;
    padding: 5px 0;
    .cx-avatar {
      img,
      .cx-avatar-text {
        width: 44px;
        height: 44px;
        line-height: 44px;
        border-radius: 50%;
        text-align: center;
        text-transform: uppercase;
        margin-top: 2px;
        margin-inline-end: 10px;
        margin-bottom: 2px;
        margin-inline-start: 0;
      }
      .cx-avatar-text {
        background: #9259cd;
        color: #fff;
        border-radius: 50%;
      }
    }
    .cx-cart {
      padding-inline-end: 18px;
    }
    .cx-action-disable {
      color: var(--cx-color-dark);
    }
  }

  .cx-action-color {
    color: $sapFioriLinkColor;
  }

  .cx-multi-cell {
    vertical-align: top;
    .cx-cell-container {
      flex-direction: column;
      align-items: flex-start;
      button {
        min-width: 0;
      }
      cx-icon {
        align-self: flex-start;
      }
    }
  }

  .cx-empty-state,
  .cx-error-state {
    display: flex;
    justify-content: left;
  }
  .ng-select {
    .ng-select-container {
      border: 0;
      .ng-arrow-wrapper {
        .ng-arrow {
          border-color: $sapFioriLinkColor transparent transparent;
        }
      }
    }
  }
  .ng-select.ng-select-focused {
    outline: none;
  }
  .ng-select.customer-list-selector {
    .ng-value-label {
      font-weight: 700;
      color: $sapFioriLinkColor;
    }
  }

  cx-pagination {
    float: right;
    a {
      margin-top: 10px;
      width: 24px;
      height: 26px;
      font-family: '72';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #0064d9;
      border-color: #ffffff;
      &:first-child {
        width: 48px;
        height: 26px;
        font-family: 'SAP-icons';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 16px;
        text-align: center;
        color: #0064d9;
        margin-top: 8px;
      }
      &:last-child {
        width: 48px;
        height: 26px;
        font-family: 'SAP-icons';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 16px;
        text-align: center;
        color: #0064d9;
        border-color: #ffffff;
        margin-top: 8px;
      }
      &.current {
        width: 32px;
        height: 26px;
        font-family: '72';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #1d2d3e;
        background: #eaecee;
        border-radius: 8px;
        border-color: #ffffff;
      }
    }
  }

  .cx-asm-create-customer-btn {
    background-color: #eaecee;
    border-color: #eaecee;
    border-radius: 8px;
    padding: 0px 10px 0px 10px;
    color: #1d2d3e;
    text-decoration: none;
    font-family: '72';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    min-height: 42px;
    min-width: 70px;

    cx-icon {
      color: #1d2d3e;
      margin-inline-end: 3px;
    }

    &:hover {
      background-color: #ffffff;
      border-width: 1px;
      border-color: #d6dade;
      border-style: solid;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .cx-asm-customer-list-btn-cancel {
    color: #0064d8;
    font-family: '72';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    border-radius: 8px;
    border-color: #ffffff;
    min-height: 42px;

    margin: 0px 20px 0px 0px;
    padding: 0px 10px 0px 10px;

    &:hover {
      color: #0064d8;
      background-color: #d9d9d9;
    }

    &:focus {
      box-shadow: none;
    }
  }
  .ng-dropdown-panel {
    width: auto;
  }
}
