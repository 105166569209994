/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

// Sizing shortcuts

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
  @include deprecate('`size()`', 'v4.3.0', 'v5');
}
