%cx-my-preferred-store {
  .cx-store-opening-hours-toggle {
    @include type('6');
    color: $cx-color-link-primary;
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
    .cx-store-opening-hours-icon {
      color: var(--cx-color-secondary);
      opacity: var(--cx-opacity, 0.5);
      @include forFeature('a11yViewHoursButtonIconContrast') {
        opacity: var(--cx-opacity, 1);
      }
    }
    padding-inline-start: 0;
    padding-top: 0.5rem;
    border: none;
    background: none;
  }

  .cx-my-preferred-store-heading {
    margin-bottom: 1.5rem;
  }

  .container {
    .cx-my-preferred-store-heading {
      margin-top: 1rem;
      margin-bottom: 0;
      color: var(--cx-color-primary);

      @include forFeature('a11yImproveContrast') {
        @include type('2');
      }
    }
    [data-test-id='preferredStoreAddressBook'] .cx-store-name {
      font-weight: 700;
      margin-bottom: 0.5rem;
    }
    cx-card {
      .cx-card-actions {
        border-width: 2px;
        border-style: solid;
        text-decoration: none;
        margin-top: 1rem;
        &:hover {
          color: var(--cx-color-primary);
          border-width: 2px;
          border-style: solid;
          border-color: var(--cx-color-primary);
          background-color: var(--cx-color-inverse);
        }

        &.active {
          color: var(--cx-color-primary);
          border-width: 2px;
          border-style: solid;
          background-color: var(--cx-color-inverse);
        }

        &.disabled {
          color: var(--cx-color-light);
          border-width: 2px;
          border-style: solid;
          border-color: var(--cx-color-light);
          opacity: 1;
        }

        .link.cx-action-link {
          margin: 0.5rem;
          font-weight: 700;
          text-decoration: none;
          width: 100%;
        }
      }
    }

    @include forFeature('a11yImproveButtonsInCardComponent') {
      cx-card {
        .cx-card-actions:not(:disabled):not(.disabled):active {
          background-color: unset;
        }
        .cx-card-actions {
          border: unset;
          margin-top: 0;
        }
        .cx-card-actions > *:only-child {
          flex: 1;
        }
      }
    }
  }
  @include cx-highContrastTheme {
    .btn-outline-text {
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-light);
    }

    .container cx-card .cx-card-actions {
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-light);
    }

    .container cx-card .cx-card-actions:not(:disabled):not(.disabled):active {
      border-color: var(--cx-color-primary);
      background-color: var(--cx-color-light);
    }
  }
}
