/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 *
 * DO NOT MODIFY THIS FILE. It preserves compatibility with Bootstrap 4.6 docs.
 */

// stylelint-disable declaration-no-important

@mixin float-left() {
  float: left !important;
  @include deprecate('The `float-left` mixin', 'v4.3.0', 'v5');
}
@mixin float-right() {
  float: right !important;
  @include deprecate('The `float-right` mixin', 'v4.3.0', 'v5');
}
@mixin float-none() {
  float: none !important;
  @include deprecate('The `float-none` mixin', 'v4.3.0', 'v5');
}
