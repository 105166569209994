@import '@spartacus/styles/scss/core';
@import '@spartacus/styles/scss/app';

@mixin visible-focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

@import './styles/index';
