%cx-add-to-wishlist {
  @include media-breakpoint-up(lg) {
    grid-column: 2;
    padding: 0 20px;
  }

  .btn {
    text-transform: none;
  }

  .button {
    &-remove {
      vertical-align: inherit;
    }

    &-add,
    &-remove {
      padding: 0;
    }

    &-text {
      margin-inline-start: 0.3em;
    }

    &-add-link {
      padding-inline-start: 0;
    }
  }
  // TODO: (CXSPA-7208) Remove feature flag next major
  @include forFeature('a11yVisibleFocusOverflows') {
    .button-add-link {
      padding-block-end: 0;
      padding-block-start: 0;
      margin-block-end: 1rem;
      margin-block-start: 1rem;
      min-height: auto;
    }
  }
}
