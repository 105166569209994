@import '@spartacus/styles/scss/core';

@mixin visible-focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;
}

@mixin button-reset {
  margin: 0;
  padding: 0;
  // font-smoothing: antialiased; left here for the future
  appearance: none;
  outline: 0;
  border: 0;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: transparent;
}

@import './bulk-pricing/index';
@import './variants/index';
@import './image-zoom/index';
@import './future-stock/index';
